import React from "react"
import { useStaticQuery } from "gatsby"
import Layout from "../components/Layout"
import MapNavigation from "../components/MapNavigation"
import imageSrc from "./quadrant_map.jpg"
import { escapeWithRegexp } from "../utils/escape"
import styled from "styled-components"

/*
<!-- Image Map Generated by http://www.image-map.net/ -->
<img src="quadrant_map.jpg" usemap="#image-map">

<map name="image-map">
    <area target="" alt="TOP_LEFT" title="TOP_LEFT" href="#" coords="" shape="rect">
    <area target="" alt="BOTTOM_LEFT" title="BOTTOM_LEFT" href="#" coords="" shape="rect">
    <area target="" alt="TOP_RIGHT" title="TOP_RIGHT" href="#" coords="" shape="rect">
    <area target="" alt="BOTTOM_RIGHT" title="BOTTOM_RIGHT" href="#" coords="" shape="rect">
</map>
*/
const areas = [
  {
    shape: "rect",
    href: "/pages/explore-gabii/previous-explorations/",
    coords: "[-1,-1,470,310]",
    name: "Previous Explorations",
    alt: "Visit previous explorations",
  },
  {
    shape: "rect",
    href: "/pages/explore-gabii/current-excavations/",
    coords: "[472, 1, 949, 312]",
    name: "Current Excavations",
    alt: "Visit current excavations",
  },
  {
    shape: "rect",
    href: "/pages/explore-gabii/the-gabii-project/",
    coords: "[471, 314, 949, 617]",
    name: "Introduction to the Gabii Project",
    alt: "Visit an introduction to the Gabii project",
  },
  {
    shape: "rect",
    href: "/pages/explore-gabii/the-environmant-of-gabii/",

    coords: "[4, 315, 469, 617]",
    name: "The Environment of Gabii",
    alt: "Visit the environment of Gabii",
  },
]

var MAP = {
  name: "Gabii Navigation",
  areas,
}

export default props => {
  return (
    <Layout>
      <Layout.Content my={24} p={20}>
        <MapNavigation
          ml="auto"
          mr="auto"
          imageSrc={imageSrc}
          map={escapeWithRegexp(JSON.stringify(MAP))}
        />
      </Layout.Content>
    </Layout>
  )
}

export const frontmatter = {
  title: "Explore Gabii",
  written: "2019-04-01",
  layoutType: "post",
  key: "explore-gabii",
  parentKey: undefined,
}

export const query = `

`
